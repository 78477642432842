/**
 * @generated SignedSource<<51a0561707a40af7d56346f777294a81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "collection" | "content" | "content_usage_confirmation" | "curriculum" | "dashboard_block_content" | "member_onboarding" | "organization" | "product_app" | "%future added value";
export type MemberGroupKind = "custom" | "default" | "role" | "test_user" | "%future added value";
export type MemberGroupRole = "admin" | "instructor" | "manager" | "owner" | "%future added value";
export type MemberGroupVisibility = "admin_only" | "everyone" | "%future added value";
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "dashboard" | "events" | "group_directory" | "link" | "member_directory" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MentionsPlugin_Mentionable$data = {
  readonly __typename: "ContentUsage";
  readonly content: {
    readonly id: string;
    readonly name: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ContentThumbnail_ContentFragment">;
  };
  readonly entity: ContentUsageEntity;
  readonly id: string;
  readonly module: {
    readonly name: string | null;
  } | null;
  readonly product: {
    readonly name: string;
  } | null;
  readonly productApp: {
    readonly customAppTitle: string | null;
    readonly kind: ProductAppKind;
  } | null;
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment">;
  readonly " $fragmentType": "MentionsPlugin_Mentionable";
} | {
  readonly __typename: "MemberGroup";
  readonly id: string;
  readonly kind: MemberGroupKind;
  readonly memberGroupMemberships: {
    readonly totalCount: number;
  };
  readonly name: string;
  readonly role: MemberGroupRole | null;
  readonly visibility: MemberGroupVisibility;
  readonly " $fragmentSpreads": FragmentRefs<"MemberGroupTagFragment">;
  readonly " $fragmentType": "MentionsPlugin_Mentionable";
} | {
  readonly __typename: "Occurrence";
  readonly datetimeRange: ReadonlyArray<string>;
  readonly id: string;
  readonly occurrenceName: string | null;
  readonly occurrenceProduct: {
    readonly occurrenceProductName: string;
    readonly type: ProductType;
  };
  readonly " $fragmentType": "MentionsPlugin_Mentionable";
} | {
  readonly __typename: "Product";
  readonly badge: {
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  } | null;
  readonly cover: string;
  readonly id: string;
  readonly name: string;
  readonly type: ProductType;
  readonly " $fragmentType": "MentionsPlugin_Mentionable";
} | {
  readonly __typename: "User";
  readonly firstName: string | null;
  readonly fullName: string;
  readonly id: string;
  readonly lastName: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  readonly " $fragmentType": "MentionsPlugin_Mentionable";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "MentionsPlugin_Mentionable";
};
export type MentionsPlugin_Mentionable$key = {
  readonly " $data"?: MentionsPlugin_Mentionable$data;
  readonly " $fragmentSpreads": FragmentRefs<"MentionsPlugin_Mentionable">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MentionsPlugin_Mentionable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarFragment"
        }
      ],
      "type": "User",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visibility",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MemberGroupTagFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupMembershipNodeConnection",
          "kind": "LinkedField",
          "name": "memberGroupMemberships",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "MemberGroup",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cover",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Badge",
          "kind": "LinkedField",
          "name": "badge",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BadgeFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Product",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "occurrenceName",
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "datetimeRange",
          "storageKey": null
        },
        {
          "alias": "occurrenceProduct",
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "product",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": "occurrenceProductName",
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Occurrence",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerPermissions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Content",
          "kind": "LinkedField",
          "name": "content",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ContentThumbnail_ContentFragment"
            },
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "product",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductApp",
          "kind": "LinkedField",
          "name": "productApp",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "customAppTitle",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Content",
          "kind": "LinkedField",
          "name": "module",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment"
        }
      ],
      "type": "ContentUsage",
      "abstractKey": null
    }
  ],
  "type": "Mentionable",
  "abstractKey": "__isMentionable"
};
})();

(node as any).hash = "eaac99e945bb02554d40aa79f1997151";

export default node;
